<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">
      <!-- Reset Password v1 -->
      <b-card class="mb-0">
        <router-link
          class="d-flex justify-content-center mb-2"
          to="/"
        >
          <b-img
            fluid
            :src="wzLogo"
            alt="Login V2"
            class="col-7 col-xl-5"
          />
        </router-link>
        <b-card-title
          title-tag="h2"
          class="font-weight-bold mb-1 mt-2"
        >
          Reset Password 🔒
        </b-card-title>
        <b-card-text class="mb-2">
          Your new password must be different from previously used passwords
        </b-card-text>

        <!-- form -->
        <validation-observer ref="simpleRules">
          <b-form
            class="auth-reset-password-form mt-2"
            method="POST"
            @submit.prevent="resetPassword"
          >
            <!-- password -->
            <b-form-group
              label="New Password"
              label-for="reset-password-new"
            >
              <validation-provider
                #default="{ errors }"
                name="Password"
                vid="password"
                rules="required|password"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid' : null"
                >
                  <b-form-input
                    id="reset-password-new"
                    v-model="password"
                    :type="password1FieldType"
                    :state="errors.length > 0 ? false : null"
                    class="form-control-merge"
                    name="reset-password-new"
                    placeholder="············"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="password1ToggleIcon"
                      @click="togglePassword1Visibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- confirm password -->
            <b-form-group
              label-for="reset-password-confirm"
              label="Confirm Password"
            >
              <validation-provider
                #default="{ errors }"
                name="Confirm Password"
                rules="required|confirmed:password"
                :custom-messages="{ confirmed: 'Password and Password Confirmation do not match' }"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid' : null"
                >
                  <b-form-input
                    id="reset-password-confirm"
                    v-model="cPassword"
                    :type="password2FieldType"
                    class="form-control-merge"
                    :state="errors.length > 0 ? false : null"
                    name="reset-password-confirm"
                    placeholder="············"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="password2ToggleIcon"
                      @click="togglePassword2Visibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- submit button -->
            <b-button
              block
              type="submit"
              variant="primary"
            >
              <b-spinner
                v-show="isProcessing"
                small
              />
              Set New password
            </b-button>
          </b-form>
        </validation-observer>

        <p class="text-center mt-2">
          <b-link :to="{ name: 'login' }">
            <feather-icon icon="ChevronLeftIcon" /> Back to login
          </b-link>
        </p>

      </b-card>
    <!-- /Reset Password v1 -->
    </div>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard,
  BCardTitle,
  BCardText,
  BForm,
  BFormGroup,
  BInputGroup,
  BInputGroupAppend,
  BLink,
  BFormInput,
  BButton,
  BSpinner,
  BImg,
} from 'bootstrap-vue'
import { required } from '@validations'
import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import useApollo from '@/plugins/graphql/useApollo'

export default {
  components: {
    BCard,
    BButton,
    BCardTitle,
    BCardText,
    BForm,
    BFormGroup,
    BInputGroup,
    BLink,
    BSpinner,
    BFormInput,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
    BImg,
  },
  data() {
    return {
      userEmail: '',
      cPassword: '',
      password: '',
      token: '',
      sideImg: require('@/assets/images/pages/reset-password-v2.svg'),
      wzLogo: require('@/assets/images/logo/logo.svg'),
      // validation
      required,
      isProcessing: false,
      // Toggle Password
      password1FieldType: 'password',
      password2FieldType: 'password',
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/reset-password-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
    password1ToggleIcon() {
      return this.password1FieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    password2ToggleIcon() {
      return this.password2FieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  beforeCreate() {
    // TODO: checkTokenValidity
    // useJwt
    //   .checkTokenValidity({ token: this.$route.query.token, email: this.$route.query.email })
    //   .catch(err => {
    //     this.showError(err.response?.data?.errors ? err.response.data.errors : err)
    //     this.$router.push('/login')
    //   })
  },
  created() {
    //   alert('ss')
    this.token = this.$route.query.token
    this.userEmail = this.$route.query.email
  },
  methods: {
    togglePassword1Visibility() {
      this.password1FieldType = this.password1FieldType === 'password' ? 'text' : 'password'
    },
    togglePassword2Visibility() {
      this.password2FieldType = this.password2FieldType === 'password' ? 'text' : 'password'
    },
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Form Submitted',
              icon: 'EditIcon',
              variant: 'success',
            },
          })
        }
      })
    },
    resetPassword() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.isProcessing = true
          useApollo.auth
            .resetPassword({
              token: this.token,
              email: this.userEmail,
              password: this.password,
              password_confirmation: this.cPassword,
            })
            .then(response => {
              this.$router.replace('/login').then(() => {
                this.showSuccess(response.data.updateForgottenPassword.message)
              })
            })
            .catch(error => {
              if (error.response?.data?.errors) {
                this.$refs.simpleRules.setErrors(error.response.data.errors)
              } else this.showErrorMessage(error)
            })
            .finally(() => {
              this.isProcessing = false
            })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
